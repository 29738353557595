import { Box } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import phoneTemplate from '../../phone.png';

export default function EmulatorOutline({ message }) {
  const style = {
    color: '#ff9c27b0',
  };
  const [showText, setShowText] = useState(true);
  var characterChunks = [];

  if (message !== null) {
    // Assuming message is an array of split messages
    characterChunks = message.map((msg, index) => ({
      data: msg,
      key: index,
    }));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setShowText(showText => !showText);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      minHeight="580px"
      bgImage={phoneTemplate}
      bgSize="cover"
      bgPosition="center"
      maxWidth="300px"
      py="6"
    >
      <Box overflowY="auto" maxHeight="480px" mt="6">
        {characterChunks.map(obj => (
          <Box
            key={obj.key}
            position="relative"
            backgroundColor="purple.500"
            minHeight="50px"
            maxWidth="200px"
            borderRadius="20"
            textColor="white"
            mx="12"
            mt="6"
            float="left"
            textAlign="left"
            pl="20px"
            pr="20px"
            py="10px"
            whiteSpace="pre-wrap" // Preserve whitespace and line breaks
          >
            {obj.data}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
