import React from 'react';
import { Navigate, Route } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem('token');
  console.log('this', isAuthenticated);

  return isAuthenticated ? children : <Navigate to="/" />;
}

export default ProtectedRoute;
