import { useTranslation } from 'react-i18next';
import { Table, Button, Form, Input } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/index.tsx';

function ViewQuota() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { userInfo } = useContext(AuthContext);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState([]);
  const [filterParams, setFilterParams] = useState({
    destination: '',
    account: '',
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const columns = [
    {
      title: 'id',
      dataIndex: 'updatedId',
    },
    {
      title: 'destination',
      dataIndex: 'destination',
    },
    {
      title: 'content',
      dataIndex: 'formattedContent',
      render: text => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: 'source',
      dataIndex: 'source',
    },
    // {
    //   title: 'send account',
    //   dataIndex: 'sendAccountId',
    // },

    {
      title: 'account',
      dataIndex: 'account',
    },
    // {
    //   title: 'provider',
    //   dataIndex: 'provider',
    // },
    // {
    //   title: 'providerType',
    //   dataIndex: 'providerType',
    // },
    {
      title: 'price (USD)',
      dataIndex: 'priceFormatted',
    },
    {
      title: 'errorMessage',
      dataIndex: 'errorMessage',
    },
    {
      title: 'response',
      dataIndex: 'response',
    },
    {
      title: 'transactionId',
      dataIndex: 'transactionId',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
    },
  ];

  const getDataSource = async (payload = {}) => {
    try {
      setLoading(true);
      console.log('testing');
      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/message/list`,
        {
          pageNo: payload.current || 1,
          pageSize: payload.pageSize || 10,
          ...filterParams,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const list = data?.data?.list;
      console.log('messages: ', list);
      const retrievedData = Array.isArray(list) ? list : [];
      const updatedData = retrievedData.map((item, index) => ({
        ...item,
        updatedId: index + 1 + (payload.current - 1) * (payload.pageSize || 10),
        formattedContent: item.content
          ? item.content.replace(/(\r\n|\n|\r)/gm, '<br>')
          : '', // Format newlines
      }));
      setDataSource(updatedData);
      // setDataSource(Array.isArray(list) ? list : []);
      setTotal(data?.data?.total || 0);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onTableChange = pagination => {
    setPagination(pagination);
    // getDataSource(pagination);
  };

  const onSearch = values => {
    let trimmedValues;
    userInfo.roleType === 'SUPER_ADMIN'
      ? (trimmedValues = {
          destination: values.destination ? values.destination.trim() : '',
          account: values.account ? values.account.trim() : '',
        })
      : (trimmedValues = {
          destination: values.destination ? values.destination.trim() : '',
        });

    setFilterParams(trimmedValues);
    setPagination({ ...pagination, current: 1 });
    console.log('[onSearch]', trimmedValues);
  };

  const onResetFilter = () => {
    setFilterParams({
      destination: '',
      account: '',
    });
    form.resetFields();
    setLoading({ ...pagination, current: 1 });
  };

  // useEffect(() => {
  //   getDataSource(pagination);
  // }, [pagination]);

  useEffect(() => {
    getDataSource(pagination);
  }, [filterParams, pagination]);

  return (
    <div style={{ overflowX: 'scroll', width: '1300px', height: '100%' }}>
      <Form
        name="Filter"
        form={form}
        layout="inline"
        onFinish={onSearch}
        style={{ marginBottom: '12px' }}
      >
        <Form.Item name="destination" label="Destination">
          <Input />
        </Form.Item>
        {/* <Form.Item name="content" label="Content">
          <Input />
        </Form.Item> */}
        {userInfo.roleType === 'SUPER_ADMIN' ? (
          <>
            {' '}
            <Form.Item name="account" label="Account">
              <Input />
            </Form.Item>
          </>
        ) : (
          <></>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            type="default"
            style={{ marginLeft: '12px' }}
            onClick={onResetFilter}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        pagination={{
          total,
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        onChange={onTableChange}
      />
    </div>
  );
}

export default ViewQuota;
