import React from 'react';
import axios from 'axios';
import { ChakraProvider, Grid, theme } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/navigation/ProtectedRoute';
import LoginContainer from './components/auth/LoginContainer';
import './i18n';
import MainPage from './components/MainPage';
import { AuthProvider } from './contexts/index.tsx';
import OrderConfirmation from './components/exapay/OrderConfirmation';

axios.interceptors.response.use(
  response => {
    const code = response?.data?.code;
    if ([20001, 20006].includes(code)) {
      window.localStorage.setItem('token', '');
      window.location.href = `${window.location.origin}`;
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginContainer />} />
          <Route
            path="/messages/:pageName"
            element={
              <ProtectedRoute>
                <AuthProvider>
                  <MainPage />
                </AuthProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/middle/order-confirmation"
            element={
              <ProtectedRoute>
                <AuthProvider>
                  <OrderConfirmation />
                </AuthProvider>
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
