import { useTranslation } from 'react-i18next';
import { Button as ChakraButton, useToast } from '@chakra-ui/react';
import {
  Space,
  Table,
  Form,
  Input,
  Modal,
  Cascader,
  Select,
  InputNumber,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import {
  API_BASE_URL,
  EXAPAY_URL,
  EXAPAY_API_KEY,
  EXAPAY_RECEIVER_EMAIL,
  EXAPAY_RECEIVER_NAME,
  EXAPAY_TOKEN,
} from '../config';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/index.tsx';
import { getNetworkList } from '../exapay/GetNetworkList';

const { Text } = Typography;

function TopUpButton(props = {}) {
  const { userInfo } = useContext(AuthContext);
  const toast = useToast();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [networkList, setNetworkList] = useState([]);
  const [paymentNetwork, setPaymentNetwork] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState('crypto');
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([
    {
      value: 'crypto',
      label: 'Crypto',
    },
    {
      value: 'tron',
      label: 'Tron',
    },
  ]);

  const getOrderId = async (amount, chainId) => {
    const url = `${EXAPAY_URL}/api/single-payment/create`;
    const response = await axios.post(url, {
      chainId: parseInt(chainId),
      token: EXAPAY_TOKEN,
      amount: amount,
      api_key: EXAPAY_API_KEY,
      receiver: {
        name: EXAPAY_RECEIVER_NAME,
        email: EXAPAY_RECEIVER_EMAIL,
      },
    });
    const orderId = response.data?.data?.id;
    return orderId;
  };

  const getTronOrderId = async (amount, wallets) => {
    const url = `${EXAPAY_URL}/api/tron/create`;
    const response = await axios.post(url, {
      token: EXAPAY_TOKEN,
      amount: amount,
      api_key: EXAPAY_API_KEY,
      receiver: {
        name: EXAPAY_RECEIVER_NAME,
        email: EXAPAY_RECEIVER_EMAIL,
        address: '',
      },
      wallets: wallets,
    });
    console.log({
      token: EXAPAY_TOKEN,
      amount: amount,
      api_key: EXAPAY_API_KEY,
      receiver: {
        name: EXAPAY_RECEIVER_NAME,
        email: EXAPAY_RECEIVER_EMAIL,
        address: '',
      },
      wallets: wallets,
    });
    const orderId = response.data?.data?.orderId;
    const wallet = response.data?.data?.wallet;
    console.log('wallet: ', wallet, 'orderId: ', orderId);
    return {
      orderId: orderId,
      wallet: wallet,
    };
  };

  const queryAccountList = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/account/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const list = data?.data?.list;
      setAccountList(Array.isArray(list) ? list : []);
    } catch (err) {
      console.log('[queryAccountList Error]', err);
    }
  };

  const onSubmitButtonClick = async e => {
    let orderId;
    let wallet;
    if (selectedPaymentType === 'tron') {
      const walletsArray = e.wallets.split(',').map(wallet => wallet.trim());
      console.log('walletsArray: ', walletsArray);
      const tronOrder = await getTronOrderId(e.smsAmount, walletsArray);
      orderId = tronOrder.orderId;
      wallet = tronOrder.wallet;
    }
    if (selectedPaymentType === 'crypto') {
      orderId = await getOrderId(e.smsAmount, e.paymentNetwork);
    }
    console.log('orderId: ', orderId);
    console.log('account List: ', accountList);
    console.log('userInfo: ', userInfo);
    // const orderId = await getOrderId(e.smsAmount, e.paymentNetwork);
    const body = {
      ...e,
      // id: accountList.find(user => user.username === userInfo.account).id,
      id: accountList[0].id,
      orderId: orderId,
    };

    try {
      setIsSubmiting(true);

      const { data } = await axios.post(
        `${API_BASE_URL}/octopus/api/admin/account/updateSmsAmount`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (data?.code === 200) {
        // toast({
        //   title: 'Success!',
        //   status: 'success',
        //   duration: 3000,
        //   isClosable: true,
        // });
        setOpen(false);
        setTimeout(() => {
          form.resetFields();
        }, 500);
      } else {
        throw new Error(data?.message);
      }
      if (selectedPaymentType === 'tron') {
        if (orderId === undefined) {
          toast({
            title:
              'There is a problem with the given wallet address (please clear all pending payments to proceed)',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        window.location.href = `/middle/order-confirmation?type=tron&amount=${e.smsAmount}&wallet=${wallet}&orderId=${orderId}`;
      }
      if (selectedPaymentType === 'crypto') {
        const chainId = e.paymentNetwork || '';
        const chainName =
          networkList.find(n => n.chainId == chainId)?.name || '';
        window.location.href = `/middle/order-confirmation?type=crypto&amount=${e.smsAmount}&chainId=${chainId}&chainName=${chainName}&orderId=${orderId}`;
      }
    } catch (error) {
      toast({
        title: error?.message || 'System Error',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmiting(false);
    }
  };

  const handlePaymentNetwork = async () => {
    const result = await getNetworkList();
    let items = result.map(({ chainId, icon, name }) => {
      return {
        value: `${chainId}`,
        label: name,
      };
    });
    setNetworkList(result);
    setPaymentNetwork(items);
  };

  const onCreateButtonClick = () => {
    setOpen(true);
  };

  const handleChange = value => {
    form.setFieldsValue({ smsAmount: value });
  };

  const validateDecimals = (rule, value) => {
    if (value !== undefined && !/^\d+(\.\d{1,2})?$/.test(value)) {
      return Promise.reject(
        'Please enter a valid amount (above 0) with up to 2 decimal places.'
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    queryAccountList();
    if (userInfo.roleType) {
      handlePaymentNetwork();
    }
  }, [userInfo]);

  return (
    <>
      <ChakraButton
        colorScheme="purple"
        type="submit"
        onClick={onCreateButtonClick}
      >
        Topup SMS Balance
      </ChakraButton>
      <Modal
        open={open}
        title="Topup SMS balance"
        okText="Submit"
        cancelText="Cancel"
        confirmLoading={isSubmiting}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmitButtonClick(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="smsAmount"
            label="Amount"
            rules={[
              {
                required: true,
                message: 'Please input the SMS amount of collection!',
              },
              { validator: validateDecimals },
            ]}
          >
            <InputNumber
              placeholder="0"
              addonAfter="$USD"
              type="number"
              min={0}
              step={0.01}
              precision={2}
              onChange={handleChange}
            />
            <div style={{ marginTop: 4 }}>
              <Tooltip>
                <Text type="secondary">
                  Only values up to 2 decimal places are accepted.
                </Text>
              </Tooltip>
            </div>
          </Form.Item>
          <Form.Item
            name="paymentType"
            label="Payment Type"
            rules={[
              {
                required: true,
                message: 'Please select the payment type!',
              },
            ]}
          >
            <Select
              placeholder="Please select"
              options={paymentTypeOptions}
              mode="single"
              onChange={value => setSelectedPaymentType(value)}
            />
          </Form.Item>
          {selectedPaymentType === 'crypto' ? (
            <>
              <Form.Item
                name="paymentNetwork"
                label="Payment Network"
                rules={[
                  {
                    required: true,
                    message: 'Please select the payment network!',
                  },
                ]}
              >
                <Select
                  placeholder="Please select"
                  options={paymentNetwork}
                  mode="single"
                />
              </Form.Item>
            </>
          ) : (
            <>
              {' '}
              <Form.Item
                name="wallets"
                label="Wallets "
                rules={[
                  {
                    required: true,
                    message: 'Please inputs the wallets, separated by commas',
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter wallet addresses separated by commas"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  // options={paymentNetwork}
                  // mode="single"
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default TopUpButton;
