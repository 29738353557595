import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      welcome: 'Welcome',
      username: 'Username',
      password: 'Password',
      show: 'Show',
      login: 'Login',
      logout: 'Logout',
      send: 'Send SMS',
      dashboard: 'Dashboard',
      view: 'View SMS',
      quotaView: 'View quota',
      account: 'Account',
      amountUsed: 'Amount Used',
      amountRemaining: 'Amount Remaining',
      smsSent: 'SMS Sent this month',
      spending: 'Spending this month',
      form: {
        origin: 'Origin Address',
        destination: 'Destination Address',
        destinationType: {
          single: 'Single Address',
          multiple: 'Multiple Address (please provide a csv file)',
        },
        name: 'Name',
        message: 'Message',
        provider: 'Provider',
        phone: 'Phone Number',
        write: 'Write your message',
        select: 'Select Provider',
        providerType: {
          otp: 'OTP',
          premium: 'Premium',
          sct: 'SCT',
        },
        submit: 'Submit',
        price: 'Get Price',
        total: 'Total Cost',
      },
      table: {
        id: 'Transaction ID',
        status: 'Status',
        delivered: 'Delivered',
        undelivered: 'Undelivered',
      },
      quota: {
        table: {
          title: 'Your available quota',
          provider: 'Provider',
          quota: 'Quota',
          useQuota: 'Used Quota',
        },
        form: {
          button: 'Add more quota',
          provider: 'Provider',
          amount: 'Amount',
          submit: 'Submit',
          message: 'Increase quota request recieved',
          update: 'Your new quota is: ',
        },
      },
    },
  },
  cn: {
    translation: {
      welcome: '歡迎',
      username: '用戶名稱',
      password: '密码',
      show: '展示',
      account: '账户管理',
      login: '登入',
      logout: '登出',
      send: '傳送短信',
      view: '瀏覽短信',
      form: {
        origin: '發貨地址',
        destination: '送貨地址',
        message: '信息',
        provider: '供應商',
        phone: '電話號碼',
        write: '填寫您的信息',
        select: '選擇供應商',
        submit: '提交',
      },
      table: {
        id: '交易帳號',
        status: '狀態',
        delivered: '已送達',
        undelivered: '未能送達',
      },
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
