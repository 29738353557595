import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';

const Navbar = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(false);
  const handleLanguageChange = language => {
    if (language === true) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('cn');
    }
  };
  return (
    <React.Fragment>
      <div>
        <Button
          backgroundColor="purple.500"
          color="white"
          onClick={() => {
            handleLanguageChange(lang);
            setLang(!lang);
          }}
        >
          {lang ? 'EN' : 'CN'}
        </Button>
      </div>
    </React.Fragment>
  );
};
export default Navbar;
