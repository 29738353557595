import React, { useEffect, useContext } from 'react';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FiCompass, FiStar, FiMenu } from 'react-icons/fi';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Navbar from '../navigation/Navbar';
import { AuthContext } from '../../contexts/index.tsx';
import axios from 'axios';
import { API_BASE_URL } from '../config';

const SidebarContent = ({ onClose, ...rest }) => {
  const { userInfo } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [routerList, setRouterList] = useState([
    // {
    //   name: t('dashboard'),
    //   icon: FiStar,
    //   path: '/messages/dashboard',
    //   visible: true,
    // },
    { name: t('send'), icon: FiStar, path: '/messages/send', visible: true },
    {
      name: t('account'),
      icon: FiCompass,
      path: '/messages/account',
      visible: true,
    },
    {
      name: t('form.provider'),
      icon: FiCompass,
      path: '/messages/provider',
      visible: false,
    },
    {
      name: t('form.message'),
      icon: FiCompass,
      path: '/messages/message',
      visible: true,
    },
    {
      name: 'Admin Account',
      icon: FiCompass,
      path: '/messages/adminUser',
      visible: false,
    },
    {
      name: 'Recharge Record',
      icon: FiCompass,
      path: '/messages/rechargeRecord',
      visible: true,
    },
    {
      name: 'Price List',
      icon: FiCompass,
      path: '/messages/priceList',
      visible: true,
    },
    {
      name: 'Admin Alerts',
      icon: FiCompass,
      path: '/messages/alerts',
      visible: false,
    },
    {
      name: 'Upload Pricing',
      icon: FiCompass,
      path: '/messages/uploadPricing',
      visible: false,
    },
  ]);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const onLogout = async () => {
    // try {
    //   const logout = await axios.post(
    //     `${API_BASE_URL}/octopus/api/admin/user/logout`
    //   );
    //   console.log('logout data: ', logout?.data);
    localStorage.clear();
    navigate('/');
    // } catch (error) {
    //   toast({
    //     title: error?.message || 'System Error.',
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //   });
    // }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (userInfo.roleType === 'SUPER_ADMIN') {
      setRouterList(routerList.map(item => ({ ...item, visible: true })));
    }
  }, [userInfo]);

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      style={{ zIndex: 2 }}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          OCTOPUS
        </Text>

        <Navbar onDrawerToggle={handleDrawerToggle} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {routerList
        .filter(n => n.visible)
        .map(link => (
          <NavItem
            key={link.name}
            icon={link.icon}
            path={link.path}
            component={link.component}
          >
            {link.name}
          </NavItem>
        ))}
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Button onClick={onLogout}>{t('logout')}</Button>
        <ColorModeSwitcher justifySelf="flex-end" />
      </Flex>
    </Box>
  );
};

const NavItem = ({ icon, children, path, component }) => {
  const navigate = useNavigate();
  const isActived = path === window.location.pathname;
  const onItemClick = () => {
    navigate(path);
  };
  return (
    <div
      href={path}
      style={{ textDecoration: 'none' }}
      component={component}
      onClick={onItemClick}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        {...(isActived ? { bg: 'purple.500', color: 'white' } : {})}
        cursor="pointer"
        _hover={{
          bg: 'purple.200',
          color: '#333',
        }}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </div>
  );
};

const MobileNav = ({ onOpen }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        OCTOPUS
      </Text>
    </Flex>
  );
};

export default function SidebarWithHeader(props) {
  const { children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={true}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </>
  );
}
