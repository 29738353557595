import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Flex,
  Input,
  Stack,
  Textarea,
  useToast,
  Box,
  Grid,
  Text,
  GridItem,
  useBreakpoint,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { Space, Table, Card } from 'antd';
import { useRef } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { useForm } from 'react-hook-form';
import { API_BASE_URL } from '../config';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import EmulatorOutline from '../emulator/EmulatorOutline';
import SetVariables from './SetVariables';

export default function Dashboard({}) {
  const { t } = useTranslation();
  const [smsRemaining, setSmsRemaining] = useState(0);
  const [smsUsed, setSmsUsed] = useState(0);
  const [totalSmsMonth, setTotalSmsMonth] = useState(0);
  const [totalSpendingMonth, setTotalSpendingMonth] = useState(0);
  const token = localStorage.getItem('token');

  const getSmsInfo = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/sms/info`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSmsRemaining(data?.data?.smsRemaining || 0);
      setSmsUsed(data?.data?.smsUsed || 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getMonthlySmsInfo = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/sms/monthly`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(data);
      setTotalSmsMonth(data?.data?.totalMessages || 0);
      setTotalSpendingMonth(data?.data?.totalPrice || 0);
      // setSmsInfo(data?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSmsInfo();
    getMonthlySmsInfo();
  }, []);

  return (
    <>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          lg: 'repeat(3,1fr)',
        }}
        gap={10}
        mb={10}
        mx={10}
      >
        <GridItem>
          <Card
            hoverable={true}
            style={{
              backgroundColor: '#805AD5',
              color: 'white',
              height: '100%',
            }}
          >
            <Box>{t('amountUsed')}</Box>
            <Box fontSize={'4xl'} fontWeight="bold">
              {smsUsed}
            </Box>
          </Card>
        </GridItem>
        <GridItem>
          <Card
            hoverable={true}
            style={{
              backgroundColor: '#805AD5',
              color: 'white',
              height: '100%',
            }}
          >
            <Box>{t('amountRemaining')}</Box>
            <Box fontSize={'4xl'} fontWeight="bold">
              {smsRemaining}
            </Box>
          </Card>
        </GridItem>
        <GridItem>
          <Card
            hoverable={true}
            style={{
              backgroundColor: '#805AD5',
              color: 'white',
              marginBottom: 10,
            }}
          >
            <Box>{t('smsSent')}</Box>
            <Box fontSize={'2xl'} fontWeight="bold">
              {totalSmsMonth}
            </Box>
          </Card>
          <Card
            hoverable={true}
            style={{ backgroundColor: '#805AD5', color: 'white' }}
          >
            <Box>{t('spending')}</Box>
            <Box fontSize={'2xl'} fontWeight="bold">
              {totalSpendingMonth}
            </Box>
          </Card>
        </GridItem>
      </Grid>
    </>
  );
}
