import { styled, useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';
import SidebarWithHeader from './navigation/Sidebar';
import SendSms from './sms/SendSms';
import Dashboard from './sms/Dashboard';
import ViewAccount from './sms/ViewAccount';
import ViewAlerts from './sms/ViewAlerts';
import ViewRechargeRecord from './sms/ViewRechargeRecord';
import ViewPriceList from './sms/ViewPriceList';
import ViewProvider from './sms/ViewProvider';
import ViewMessage from './sms/ViewMessage';
import ViewAdminUser from './sms/ViewAdminUser';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewSms from './sms/ViewSms';
import ViewQuota from './sms/ViewQuota';
import { useTranslation } from 'react-i18next';
import UploadPricing from './sms/UploadPricing';

const smVariant = { navigation: 'drawer', navigationButton: true };
const mdVariant = { navigation: 'sidebar', navigationButton: false };

const MainPage = props => {
  const { t, i18n } = useTranslation();

  const params = useParams();
  const { pageName } = params;
  // const { pageName } = props.match.params;

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return (
    <>
      <SidebarWithHeader
        {...props}
        variant={variants?.navigation}
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
      >
        {console.log(params)}
        {
          {
            dashboard: <Dashboard />,
            send: <SendSms />,
            view: <ViewSms {...props} />,
            account: <ViewAccount {...props} />,
            provider: <ViewProvider {...props} />,
            quota: <ViewQuota {...props} />,
            message: <ViewMessage {...props} />,
            adminUser: <ViewAdminUser {...props} />,
            alerts: <ViewAlerts {...props} />,
            rechargeRecord: <ViewRechargeRecord {...props} />,
            priceList: <ViewPriceList {...props} />,
            uploadPricing: <UploadPricing {...props} />,
          }[pageName]
        }
      </SidebarWithHeader>
    </>
  );
};

export default MainPage;
