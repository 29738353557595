import React, { useEffect, useState, useRef } from 'react';
import { API_BASE_URL } from '../components/config';
import axios from 'axios';

interface AuthProviderProps {
  children: React.ReactNode;
}

declare global {
  interface Window {
    ethereum?: any;
  }
}

interface UserInfoInterface {
  account: string;
  id: string;
  roleType?: 'CUSTOMER' | 'SUPER_ADMIN';
}

interface AuthInterface {
  userInfo: UserInfoInterface;
  setUserInfo: (
    updateFnOrValue:
      | ((prevState: UserInfoInterface) => UserInfoInterface)
      | UserInfoInterface
  ) => void;
  checkUser: () => void;
}

const defaultState = {
  userInfo: {
    account: '',
    id: '',
    roleType: undefined,
  },
  setUserInfo: (
    updateFnOrValue:
      | ((prevState: UserInfoInterface) => UserInfoInterface)
      | UserInfoInterface
  ) => {},
  checkUser: () => {},
};

const AuthContext = React.createContext<AuthInterface>(defaultState);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // const [userInfo, setUserInfo] = useState<UserInfoInterface>({
  //   account: '',
  //   id: '',
  //   roleType: undefined,
  // });
  const [userInfo, setUserInfo] = useState<UserInfoInterface>(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    return storedUserInfo
      ? JSON.parse(storedUserInfo)
      : {
          account: '',
          id: '',
          roleType: undefined,
        };
  });
  const hasCheckedUser = useRef(false);

  const checkUser = async () => {
    console.log('checkUser');
    try {
      const token = localStorage.getItem('token');
      console.log('token in checkUser: ', token);
      const { data } = await axios.get(
        `${API_BASE_URL}/octopus/api/admin/user/userinfo`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('user info in check user function after api: ', data?.data);
      // setUserInfo(prevState => ({
      //   ...prevState,
      //   ...(data?.data || {}),
      // }));
      setUserInfo(prevState => {
        const updatedUserInfo = {
          ...prevState,
          ...(data?.data || {}),
        };
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
        return updatedUserInfo;
      });
    } catch (err) {
      console.log('[getUserInfo Error]', err);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !hasCheckedUser.current) {
      checkUser();
      hasCheckedUser.current = true;
    }
  }, []);

  return (
    <AuthContext.Provider value={{ userInfo, setUserInfo, checkUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
