import {
  Button,
  Stack,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useToast,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination } from 'react-table';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

function CustomTable({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}

function ViewQuota() {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [quota, setQuota] = useState([]);
  const [show, setShow] = useState(false);
  const [provider, setProvider] = useState('');
  const [amount, setAmount] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [newAmount, setNewAmount] = useState(0);
  const [amountAdd, setAmountAdd] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const getQuota = async e => {
    let headers = { Authorization: token };

    try {
      const { data } = await axios.get(`${API_BASE_URL}/quota`, {
        headers: headers,
      });
      console.log('data: ', data);
      setQuota(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast({
        title: 'Something went wrong.',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleChange = async e => {
    setShow(true);
  };
  const onSend = async e => {
    // console.log('provider: ', provider);
    // console.log('amount: ', amount);
    setSubmit(true);
    let headers = { Authorization: token };
    let body = {
      provider: provider,
      amount: amount,
    };
    console.log('body: ', body);
    try {
      const data = await axios.post(`${API_BASE_URL}/quota/add`, body, {
        headers: headers,
      });
      console.log('axios: ', data);
      setNewAmount(data.data['new quota']);
      setAmountAdd(true);
    } catch {
      toast({
        title: 'Something went wrong.',
        description: 'Try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    // try {
    //   const { test } = await axios.post(`${API_BASE_URL}/quota/add`, body, {
    //     headers: headers,
    //   });
    //   console.log('add: ', test);
    //   // setQuota(data);
    //   // setLoading(false);
    // } catch (err) {
    //   console.log(err);
    //   toast({
    //     title: 'Something went wrong.',
    //     description: 'Try again.',
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //   });
    // }
  };

  useEffect(() => {
    getQuota();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('quota.table.title'),
        columns: [
          {
            Header: t('quota.table.provider'),
            accessor: 'provider',
          },
          {
            Header: t('quota.table.quota'),
            accessor: 'quota',
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <CustomTable columns={columns} data={quota} />
      <Button colorScheme="purple" type="submit" mt="12" onClick={handleChange}>
        {t('quota.form.button')}
      </Button>
      {show ? (
        <>
          <Grid gap={4} templateColumns="repeat(5,1fr)">
            <GridItem colSpan={2}>
              <form onSubmit={handleSubmit(onSend)}>
                <Stack
                  spacing={3}
                  display={{ md: 'flex' }}
                  justifyContent={'space-between'}
                  mt="12"
                >
                  <FormControl isRequired isInvalid={errors['source']}>
                    <FormLabel>{t('quota.form.provider')}</FormLabel>
                    <Input
                      name="variable1"
                      placeholder="Variable 1"
                      onChange={e => {
                        setProvider(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('quota.form.amount')}</FormLabel>
                    <Input
                      name="variable2"
                      placeholder="Variable 2"
                      onChange={e => {
                        setAmount(e.target.value);
                      }}
                    />
                  </FormControl>
                  <Button colorScheme="purple" type="submit">
                    {t('quota.form.submit')}
                  </Button>
                  {amountAdd ? (
                    <>
                      <Text>{t('quota.form.message')}</Text>
                      <Text as="b">
                        {t('quota.form.update')} {newAmount}
                      </Text>
                    </>
                  ) : (
                    <></>
                  )}
                </Stack>
              </form>
            </GridItem>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ViewQuota;
